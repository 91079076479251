export default {
  socketio_uri: { protocol: "https", host: `api-pp.betterstreet.org`, path: "/ws" },
  api_uri: `https://api-pp.betterstreet.org/2`,
  file_storage_read_url: "https://files.betterstreet.org",
  google_api_key:"AIzaSyBz32yDstb1X2jsZz_MGaGDKwTWDyMGRfw",
  default_map_position: {
    type:"Point",
    coordinates:[1.8069499, 51.1773266]
  },
  default_map_zoom: 6,
  facebook_app_id: '282529923149928',
  conditionUrl: "https://betterstreet.gitbook.io/conditions-betterstreet/",
  admin_url: "https://admin-pp.betterstreet.org",
  env: "development",
  supportEmailDefault: "support@betterstreet.org",
  supportEmailBenelux: "support@inforius.be",
};

window.process = {
  env: 'developpement'
};